import {
  faReact,
  faPython,
  faHtml5,
  faCss3,
  faJs,
  faSass,
  faWordpress,
  faUnity,
  faGit,
  faNodeJs,
  faFigma,
  faDocker,
  faNpm,
  faBootstrap,
  faFontAwesome,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import {
  AiOutlineGlobal,
  AiOutlineInstagram,
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineCode,
  AiOutlineHtml5,
  AiOutlineFormatPainter,
  AiOutlineApi,
  AiOutlineCloudServer,
} from 'react-icons/ai';

import {
  FaReact,
  FaSass,
  FaBootstrap,
  FaFigma,
  FaCogs,
  FaJs,
  FaTerminal,
  FaDocker,
  FaNpm,
  FaWordpress,
  FaElementor,
  FaVectorSquare,
  FaPalette,
  FaGithub,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';

import { FiFramer, FiLayout } from 'react-icons/fi';
import { IoLogoJavascript } from 'react-icons/io';
import { DiJqueryLogo, DiCss3, DiNodejsSmall } from 'react-icons/di';
import {
  SiTailwindcss,
  SiSemanticuireact,
  SiFontawesome,
  SiMaterialdesignicons,
  SiAdobexd,
  SiDjango,
  SiTypescript,
  SiNotion,
  SiAdobeindesign,
  SiAffinitydesigner,
  SiAffinityphoto,
  SiFirebase,
  SiMongodb,
} from 'react-icons/si';
import { TfiLayoutMediaLeft } from 'react-icons/tfi';
import { VscDebugLineByLine } from 'react-icons/vsc';
import { TbCloudComputing } from 'react-icons/tb';

export const AboutContent = [
  {
    text: (
      <p className='text-justify font-extralight text-align-justify '>
        Welcome to my portfolio website! My name is Fadi Azzam, and I am a web
        developer. I was born and raised in Sweida, Syria, and I have been
        living in Germany since 2016. In September 2021, I successfully
        completed my Bachelor's degree in Media Informatics at the{' '}
        <a
          className='text-primary-400 hover:text-primary-300 font-bold'
          target='_blank'
          rel='noreferrer'
          href='https://www.ba-dresden.de/'
        >
          {' '}
          Berufsakademie Dresden
        </a>
        . Currently, I work as a Frontend Developer at{' '}
        <a
          className='text-primary-400 hover:text-primary-300 font-bold'
          target='_blank'
          rel='noreferrer'
          href='https://infai.org/'
        >
          {' '}
          InfAI | Institute for Applied Informatics
        </a>
        . My goal is to develop websites and applications that are not only
        functional but also aesthetically pleasing and user-friendly.
      </p>
    ),
  },
];

export const skillsContent = [
  {
    title: 'React js',
    icon: faReact,
    frameworks: [''],
  },
  {
    title: 'JavaScript',
    icon: faJs,
    frameworks: [''],
  },
  {
    title: 'Django',
    icon: faPython,
    frameworks: [''],
  },
  {
    title: 'JQuery',
    icon: faJs,
    frameworks: [''],
  },
  {
    title: 'HTML5',
    icon: faHtml5,
    frameworks: [''],
  },
  {
    title: 'Bootstrap 5',
    icon: faBootstrap,
    frameworks: [''],
  },
  {
    title: 'Tailwind CSS',
    icon: faBootstrap,
    frameworks: [''],
  },
  {
    title: 'StyledComponent',
    icon: faBootstrap,
    frameworks: [''],
  },
  {
    title: 'Semantic UI',
    icon: faBootstrap,
    frameworks: [''],
  },
  {
    title: 'Font Awesome 6',
    icon: faFontAwesome,
    frameworks: [''],
  },
  {
    title: 'CSS3',
    icon: faCss3,
    frameworks: [''],
  },
  {
    title: 'Sass',
    icon: faSass,
    frameworks: [''],
  },
  {
    title: 'UI/UX-Design',
    icon: faSass,
    frameworks: [''],
  },
  {
    title: 'Wordpress',
    icon: faWordpress,
    frameworks: [''],
  },
  {
    title: 'Elementor',
    icon: faWordpress,
    frameworks: [''],
  },
  {
    title: 'Python',
    icon: faPython,
    frameworks: [''],
  },
  {
    title: 'Grafikdesign',
    icon: faSass,
    frameworks: ['Affinity Designer & Photo'],
  },
  {
    title: 'Datenanalyse',
    icon: faPython,
    frameworks: ['MS Office'],
  },
  {
    title: 'Adobe InDesign',
    icon: faPython,
    frameworks: [''],
  },
  {
    title: 'Gamedesign | Leveldesign',
    icon: faUnity,
    frameworks: [''],
  },
  {
    title: 'Git + Github',
    icon: faGit,
    frameworks: [''],
  },
  {
    title: 'Bash',
    icon: faGit,
    frameworks: [''],
  },
  {
    title: 'Chrome Dev Tools',
    icon: faGit,
    frameworks: [''],
  },
  {
    title: 'RESTful API',
    icon: faGit,
    frameworks: [''],
  },
  {
    title: 'Node js',
    icon: faNodeJs,
    frameworks: [''],
  },
  {
    title: 'Express js',
    icon: faNodeJs,
    body: '',
    frameworks: [''],
  },
  {
    title: 'Redux',
    icon: faNodeJs,
    frameworks: [''],
  },
  {
    title: 'Figma',
    icon: faFigma,
    frameworks: [''],
  },
  {
    title: 'Adobe XD',
    icon: faFigma,
    body: '',
    frameworks: [''],
  },
  {
    title: 'Wireframing & protoyping',
    icon: faFigma,
    body: '',
    frameworks: [''],
  },
  {
    title: 'Docker',
    icon: faDocker,
    frameworks: [''],
  },
  {
    title: 'Firebase',
    icon: faDocker,
    frameworks: [''],
  },
  {
    title: 'Npm',
    icon: faNpm,
    frameworks: [''],
  },
  {
    title: 'Framer Motion',
    icon: faNpm,
    frameworks: [''],
  },
  {
    title: 'Notion',
    icon: faNpm,
    frameworks: [''],
  },
];

export const skillsHierarchyContent = [
  [
    {
      label: 'Frontend Development',
      icon: <AiOutlineCode />,
      children: [
        {
          icon: <FaReact />,
          name: 'React.js',
        },
        {
          icon: <IoLogoJavascript />,
          name: 'JavaScript',
        },
        {
          icon: <SiTypescript />,
          name: 'TypeScript',
        },
        {
          icon: <DiJqueryLogo />,
          name: 'jQuery',
        },
        {
          icon: <AiOutlineHtml5 />,
          name: 'HTML5',
        },
        {
          icon: <DiCss3 />,
          name: 'CSS3',
          children: [{ icon: <FaSass />, name: 'Sass' }],
        },
        {
          icon: <FaBootstrap />,
          name: 'Bootstrap 5',
        },
        {
          icon: <SiTailwindcss />,
          name: 'Tailwind CSS',
        },
        {
          icon: <AiOutlineFormatPainter />,
          name: 'Styled Components',
        },
        {
          icon: <SiSemanticuireact />,
          name: 'Semantic UI',
        },
        {
          icon: <SiFontawesome />,
          name: 'Font Awesome 6',
        },
      ],
    },
  ],
  [
    {
      label: 'Backend Development',
      icon: <FaCogs />,

      children: [
        {
          icon: <SiMongodb />,
          name: 'MongoDB',
        },
        {
          icon: <DiNodejsSmall />,
          name: 'Node.js',
          children: [
            {
              icon: <FaJs />,
              name: 'Express.js',
            },
          ],
        },

        {
          icon: <AiOutlineApi />,
          name: 'RESTful API',
        },
      ],
    },
  ],
  [
    {
      label: 'Graphic Design and Layout',
      icon: <FaPalette />,
      children: [
        {
          name: 'Adobe InDesign',
          icon: <SiAdobeindesign />,
        },
        {
          name: 'Affinity Designer',
          icon: <SiAffinitydesigner />,
        },
        {
          name: 'Affinity Phone',
          icon: <SiAffinityphoto />,
        },
        {
          name: 'UI/UX Design',
          icon: <SiMaterialdesignicons />,
          children: [
            { icon: <FaFigma />, name: 'Figma' },
            { icon: <SiAdobexd />, name: 'Adobe XD' },

            {
              name: 'Wireframing & Prototyping',
              icon: <TfiLayoutMediaLeft />,

              children: [{ icon: <FiFramer />, name: 'Framer Motion' }],
            },
          ],
        },
      ],
    },
  ],
  [
    {
      label: 'Version Control and Development Tools',
      icon: <FaTerminal />,

      children: [
        {
          icon: <AiOutlineGithub />,
          name: 'Git + GitHub',
        },
        {
          icon: <FaTerminal />,
          name: 'Bash',
        },
        {
          icon: <VscDebugLineByLine />,
          name: 'Chrome Dev Tools',
        },
        {
          icon: <FaDocker />,
          name: 'Docker',
        },
        {
          icon: <FaNpm />,
          name: 'Npm',
        },
        {
          icon: <SiNotion />,
          name: 'Notion',
        },
      ],
    },
  ],
  [
    {
      label: 'Content Management Systems (CMS)',
      icon: <FiLayout />,

      children: [
        {
          name: 'WordPress',
          icon: <FaWordpress />,
          children: [{ name: 'Elementor', icon: <FaElementor /> }],
        },
      ],
    },
  ],

  [
    {
      label: 'Cloud Services and Platforms',
      icon: <TbCloudComputing />,

      children: [
        {
          name: 'Firebase',
          icon: <SiFirebase />,
        },
        {
          name: 'MongoDB Atlas',
          icon: <SiMongodb />,
        },
      ],
    },
  ],
];

export const skillsInProgressContent = [
  {
    title: 'React Native',
    icon: '',
  },
  {
    title: 'WebGL & Three.js',
    icon: '',
  },
  {
    title: 'Java',
    icon: '',
  },
];

export const workExperienceContent = [
  {
    date: [
      {
        start: '01.2023',
        end: 'Present',
      },
    ],
    title: 'Frontend Developer',
    description: `As a Frontend Developer, I focus on web application development for data analysis, working on both frontend and backend.`,
    company: 'InfAI | Institute for Applied Informatics',
    tasks: [
      'Developing innovative web applications using React.js, TypeScript, Tailwind CSS, Redux.js, and Jest.',
      'Integrating RESTful APIs, version control with Git, and applying object-oriented programming.',
      'Using agile methods in software development.',
      'Testing and quality control of web applications.',
    ],
    links: [],
  },
  {
    date: [
      {
        start: '10.2021',
        end: '12.2022',
      },
    ],
    title: 'Frontend Developer',
    description:
      'Developing innovative applications within the EU project heartbit, aimed at digitally storing and analyzing patient data.',
    company: 'University Hospital Carl Gustav Carus',
    tasks: [
      'Developing and designing modern web applications using React JS, TypeScript, JavaScript, Redux, HTML5, and CSS3.',
      'Leading and coordinating projects, ensuring timely and budget-friendly project completion.',
      'Contributing creatively to user-friendly design using Figma and Adobe XD.',
    ],
    links: [
      '<a href="https://heartbit40.com/" target="_blank" className="link-primary">EU Project heartbit</a>',
    ],
  },
  {
    date: [
      {
        start: '10.2017',
        end: '09.2021',
      },
    ],
    title: 'Dual Student',
    description:
      'Worked half the semester in the company and studied the other half at the academy each semester.',
    company: 'Fraunhofer Institute for Material and Beam Technology IWS',
    tasks: [
      'Web development (planning, construction, and maintenance), web design (wireframing and prototyping), automated testing.',
      'Practical study with a focus on OOP, web programming, databases, and project management.',
    ],
    links: [],
  },
  {
    date: [
      {
        start: '06.2017',
        end: '09.2017',
      },
    ],
    title: 'Internship',
    description:
      'Introduction tasks in the field of OOP and web programming in the field of Generating and Printing, Image Editing and Data Management Working Group.',
    company: 'Fraunhofer Institute for Material and Beam Technology IWS',
    tasks: [
      'Software development, design tasks, database management, and web development.',
    ],
    location: 'Dresden, Germany',
    links: [],
  },
];

export const educationContent = [
  {
    date: [
      {
        start: '10.2017',
        end: '09.2021',
      },
    ],
    title: 'Bachelor of Engineering in IT - Media Informatics',
    grade: 'Degree: 2.3',
    description: `Dual study program with a focus on Media Informatics at BA Dresden, in partnership with Fraunhofer IWS as the practical partner. Key study areas included: OOP, web programming, image editing and prepress, databases, web and print design, English for media, audio and video technology, computer architecture and networks, interactive media, journalism, project management, business administration, and marketing.`,
    company: 'BA Dresden | State Academy of Dresden',
    location: 'Dresden, Germany',
  },
  {
    date: [
      {
        start: '05.2016',
        end: '06.2017',
      },
    ],
    title: 'German Language Courses',
    description: '',
    company: 'Language School for Everyone',
    location: 'Dresden, Germany',
  },
  {
    date: [
      {
        start: '10.2014',
        end: '09.2015',
      },
    ],
    title: 'Computer Science Studies',
    description: '',
    company: 'Technical College for Industry in Sweida, Syria',
    location: 'Sweida, Syria',
  },
  {
    date: [
      {
        start: '10.2011',
        end: '09.2014',
      },
    ],
    title: 'Specialized High School',
    description: 'Specialized High School Diploma in Computer Technology',
    company: 'Nawras Abed Albakie Computer Technology School, Sweida, Syria',
    location: 'Sweida, Syria',
  },
];

export const hobbiesContent = [
  {
    date: [
      {
        start: '09.2020',
        end: 'Present',
      },
    ],
    title: 'Coordinator of an Educational Program',
    description:
      'I actively participate in a local education project called "Intercultural Exchange for People in the Best Years." This initiative brings together older individuals from diverse backgrounds, encouraging them to share experiences and collaborate on projects.',
    company: 'Ingenieure ohne Grenzen e. V.',
    location: 'Dresden / Deutschland',
  },
];

export const socialLinks = [
  {
    title: 'Fadi Azzam',
    icon: <FaInstagram />,
    link: 'https://www.instagram.com/fadi.azzam_/',
  },
  {
    title: 'Fadi Azzam',
    icon: <FaGithub />,
    link: 'https://github.com/FadiAzzam',
  },
  {
    title: 'Fadi Azzam',
    icon: <FaLinkedin />,
    link: 'https://www.linkedin.com/in/fadi-azzam-274902196/',
  },
  {
    title: 'fadiazzam.de',
    icon: <AiOutlineGlobal />,
    link: 'https://www.linkedin.com/in/fadi-azzam-274902196/',
  },
];
