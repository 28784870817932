import React from 'react';

import Hero from '../components/ui/Hero';
import About from '../components/ui/About';
import Skills from '../components/ui/Skills';
import SkillsInProgress from '../components/ui/SkillsInProgress';
import Projects from '../components/ui/Projects';
import WorkExperience from '../components/ui/WorkExperience';
import Education from '../components/ui/Education';
import Hobbies from '../components/ui/Hobbies';

import { SectionTitle } from '../components/common/SectionHeader';
import Tabs from '../components/common/Tabs';

const data = [
  {
    id: '1',
    tabTitle: 'WorkExperience',
    tabContent: <WorkExperience />,
  },
  {
    id: '2',
    tabTitle: 'Education',
    tabContent: <Education />,
  },
  {
    id: '3',
    tabTitle: 'Hobbies',
    tabContent: <Hobbies />,
  },
];

const LandingPage = () => {
  return (
    <div className='container'>
      <Hero />
      <About />
      <Skills />
      <SkillsInProgress />
      <Projects />
      <SectionTitle subTitle='4+ YEARS OF EXPERIENCE' title='My Resume' />
      <div>
        <Tabs data={data} />
      </div>
    </div>
  );
};

export default LandingPage;
