/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="z-50 transition-all container ">
      <nav className="py-4 md:py-8">
        <div className="relative p-3 w-full flex-wrap md:flex-nowrap flex items-center md:items-baseline mx-auto drop-shadow-2xl  ease-in-out bg-gray-700/20 backdrop-blur justify-between rounded-full">
          <ul className="text-base md:flex md:justify-between md:pt-0 items-center  ">
            <li className="p-3 ">
              <NavLink
                className="hover:text-primary-400 text-sm flex items-center gap-3"
                to="/"
              >
                fadiazzam.de
              </NavLink>
            </li>

            <li className="p-3 ">
              <NavLink
                className="hover:text-primary-400 text-sm flex items-center gap-3"
                to="/contact"
              >
                Contact
              </NavLink>
            </li>

            <li className="p-3 ">
              <NavLink
                className="hover:text-primary-400 text-sm flex items-center gap-3"
                to="/imprint"
              >
                Imprint
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
