import React from "react";
import { motion } from "framer-motion";

const Progress = () => {
  return (
    <div className="progress py-3">
      <span className="tak"></span>
      <span className="tak"></span>
      <span className="tak"></span>
      <span className="tak"></span>
      <span className="tak"></span>
      <span className="tak"></span>
      <span className="tak inTacked01"></span>
      <span className="tak inTacked02"></span>
      <span className="tak inTacked03"></span>
    </div>
  );
};

export default Progress;
