import React, { useEffect } from "react";
import { motion, useScroll, useSpring } from "framer-motion";

import { useParams, Link, Navigate, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { MdTransitEnterexit } from "react-icons/md";

import Button from "../components/common/Button";

import blogPosts from "./blogPosts";
import RelatedPosts from "./RelatedPosts";

function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts.find((post) => post.slug === slug);
  const location = useLocation();

  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  if (!post) {
    return <Navigate to="/blog" />;
    // or return <p>Post not found</p>;
  }

  return (
    <>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-primary-400 z-50 h-1 origin-left"
        style={{ scaleX }}
      />
      <div className="container py-36">
        <div className="flex items-center flex-col text-center gap-6 my-3">
          <div className="flex justify-center items-center gap-3">
            <Link className="font-light" to={`/blog`}>
              Home
            </Link>
            <FaAngleRight />
            <Link className="font-light" to={`/blog/category/${post.category}`}>
              {post.category}
            </Link>
          </div>
          <h1 className="  text-3xl md:text-5xl font-extrabold leading-none tracking-tight">
            {post.title}
          </h1>

          <div className="flex items-center space-x-4">
            <img
              className="w-12 h-12 rounded-full object-cover"
              src="./me.jpeg"
              alt="Fadi Azzam"
            />
            <div className="font-medium dark:  text-left">
              <p>
                By <strong>{post.author}</strong>
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Updated {post.date}
              </p>
            </div>
          </div>

          <img className="md:w-1/2 mx-auto" src={post.image} alt={post.title} />
        </div>
        <section className="flex gap-12 flex-col md:flex-row ">
          <article className="basis-2/3 text-align-justify ">
            {post.content.map((postContent, i) => {
              return <React.Fragment key={i}>{postContent}</React.Fragment>;
            })}
            <p className="my-6">
              <Button
                text="zurück"
                to="/blog"
                type="btn-sm"
                icon={<MdTransitEnterexit />}
              />
            </p>
          </article>
          <aside className="basis-1/3 relative">
            <RelatedPosts />
          </aside>
        </section>
      </div>
    </>
  );
}

export default BlogPost;
