import React from 'react';
import Progress from '../../components/common/Progress';
import SectionHeader from '../../components/common/SectionHeader';
import Card from '../../components/common/Card';

import { skillsInProgressContent } from '../../constants/index';

const SkillsInProgress = () => {
  return (
    <div className='py-16'>
      <SectionHeader
        subTitle={'skills in progress'}
        title={'Training Goals Checklist'}
      />
      <Progress />

      <article className='flex gap-3 flex-wrap flex-col md:flex-row'>
        {skillsInProgressContent.map((item, i) => {
          return <Card key={i} item={item} />;
        })}
      </article>
    </div>
  );
};

export default SkillsInProgress;
