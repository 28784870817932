import React from "react";
import { Link, useNavigate } from "react-router-dom";
import blogPosts from "./blogPosts";

function RelatedPosts(props) {
  let navigate = useNavigate();

  return (
    <div className="sticky top-6 left-0 my-6 ">
      {blogPosts.map((post, index) => (
        <div
          className="flex flex-col my-3 border border-gray-50/10 word-break-word drop-shadow-2xl backdrop-blur"
          key={index}
        >
          <div
            className="flex flex-col lg:flex-row cursor-pointer"
            onClick={() => navigate(`/blog/${post.slug}`)}
          >
            <div className="basis-1/2 shrink-0">
              <img
                className="w-100 md:h-full"
                src={post.image}
                alt={post.title}
              />
            </div>
            <div className="flex flex-col basis-1/2 p-3">
              <div>
                <span
                  className="text-sm text-primary-400 pointer uppercase tracking-widest"
                  onClick={() => navigate(`/blog/category/${post.category}`)}
                >
                  {post.category}
                </span>
              </div>
              <div className="grow">
                <h5 className="text-base">{post.title}</h5>
                <small>{post.date}</small>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RelatedPosts;
